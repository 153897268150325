/* eslint-disable react-hooks/exhaustive-deps */
// These dependencies are bundled within praxis-scripts
import { useEffect, useState } from 'react'
import Header from '../../Header/Header'
import { Button, Grid, TextField, Typography } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { toggleRender } from '../../MatLinearProgress/actionCreator'
import { setMessage } from '../../UserFeedback/actionCreator'
import {
  setVisits,
  setWorkOrder,
  setWorkOrders,
} from '../WorkOrdersStore/actionCreator'
import { getURLSearchParams, setURLSearchParams } from '../../../windowManager'
import { fetchData } from '../../../service/common/HttpService'
import { connect } from 'react-redux'
import { AlertGroup } from '../common/AlertGroup'
import Clipboard from '../Clipboard/Clipboard'
import SubNavTabs from '../SubNavTabs/SubNavTabs'
import ChangeStatus from './ChangeStatus'
import WorkOrderHistory from './WorkOrderHistory'
import ImageCarousel from '../../ImageCarousel/ImageCarousel'
import ImageDialog from '../../ImageCarousel/ImageDialog'
import { Alert } from '@material-ui/lab'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import AttachmentUploadButton from '../../AttachmentUpload/AttachmentUploadButton'
import { useAnalytics } from '@praxis/component-analytics'

const useStyles = makeStyles((theme) =>
  createStyles({
    gridContainer: {
      marginBottom: theme.spacing(1),
    },
    inputProps: {
      backgroundColor: theme.palette.secondary.action,
      color: theme.palette.primary.main,
    },
    gridContainerFullWidth: {
      margin: theme.spacing(0, 2),
    },
    clipboardWrapper: {
      position: 'relative',
      top: -theme.spacing(0.3),
    },
    typography: {
      marginRight: theme.spacing(4),
    },
    subheaderWrapper: {
      margin: theme.spacing(0, 2),
    },
    statusInfo: {
      margin: theme.spacing(1.5, 1.2),
    },
  }),
)

const WorkOrderDetails = ({
  toggleRender,
  toggle,
  setVisits,
  setWorkOrder,
  workOrder,
  shouldRender,
  userInfo,
}) => {
  const classes = useStyles()
  const [error, setError] = useState(false)
  const [numWorkOrders, setNumWorkOrders] = useState(0)
  const workOrderArray = JSON.parse(localStorage.getItem('workOrderArray'))
  const [woNumsArray, setWoNumsArray] = useState(workOrderArray)
  const [displayCount, setDisplayCount] = useState(0)
  const urlSearchParams = getURLSearchParams()
  const workOrderNumber = urlSearchParams.get('work_order_number')
  const totalCount = workOrderArray?.length
  const currentCount = parseInt(urlSearchParams.get('current_count'))
  const companyId = parseInt(urlSearchParams.get('company_id'))
  const [index, setIndex] = useState(0)
  const { trackCustomEvent } = useAnalytics()

  useEffect(() => {
    loadWorkOrderData()
    setCurrentWorkOrderIndex()
  }, [toggle])

  const onHashChange = (event) => {
    if (event) {
      const oldUrl = event?.oldURL
      const newUrl = event?.newURL
      if (
        oldUrl?.indexOf('open') !== -1 ||
        newUrl?.indexOf('open') !== -1 ||
        oldUrl?.indexOf('help') !== -1 ||
        newUrl?.indexOf('help') !== -1 ||
        oldUrl?.indexOf('add_work_log') !== -1 ||
        newUrl?.indexOf('add_work_log') !== -1 ||
        oldUrl?.indexOf('add_note') !== -1 ||
        newUrl?.indexOf('add_note') !== -1 ||
        oldUrl?.indexOf('upload') !== -1 ||
        newUrl?.indexOf('upload') !== -1 ||
        oldUrl?.indexOf('Inputs') !== -1 ||
        newUrl?.indexOf('Inputs') !== -1 ||
        oldUrl?.indexOf('image_dialog') !== -1 ||
        newUrl?.indexOf('image_dialog') !== -1 ||
        oldUrl?.indexOf('add_vendor_decline_reason') !== -1 ||
        newUrl?.indexOf('add_vendor_decline_reason') !== -1 ||
        oldUrl?.indexOf('confirm_billing') !== -1 ||
        newUrl?.indexOf('confirm_billing') !== -1
      ) {
        return false
      }
    }
    loadWorkOrderData()
    setCurrentWorkOrderIndex()
  }

  useEffect(() => {
    window.addEventListener('hashchange', onHashChange)
    onHashChange()
    return () => window.removeEventListener('hashchange', onHashChange)
  }, [])

  const setCurrentWorkOrderIndex = () => {
    const urlSearchParams = getURLSearchParams()
    const wonum = urlSearchParams.get('work_order_number')
    const newWoNumsArray = workOrderArray
    const currentIndex = newWoNumsArray?.findIndex((number) => number === wonum)
    calculateDisplayCount(currentIndex)
    setWoNumsArray(newWoNumsArray)
  }

  const calculateDisplayCount = (currentIndex) => {
    const calculateDisplayCount = totalCount * 0 + currentIndex + 1
    setURLSearchParams(
      [
        {
          name: 'current_count',
          value: calculateDisplayCount,
        },
      ],
      true,
    )
    setIndex(currentIndex)
    setDisplayCount(calculateDisplayCount)
  }

  const handleNext = () => {
    const nextIndex = index + 1
    const paramsCount = parseInt(urlSearchParams.get('current_count'))

    setURLSearchParams([
      {
        name: 'work_order_number',
        value: woNumsArray[nextIndex],
      },
      {
        name: 'current_count',
        value: paramsCount + 1,
      },
    ])
    setIndex(nextIndex)
    calculateDisplayCount(nextIndex)
  }

  const handlePrevious = () => {
    const previousIndex = index - 1
    const paramsCount = parseInt(urlSearchParams.get('current_count'))
    setURLSearchParams([
      {
        name: 'work_order_number',
        value: woNumsArray[previousIndex],
      },
      {
        name: 'current_count',
        value: paramsCount - 1,
      },
    ])
    setIndex(previousIndex)
    calculateDisplayCount(previousIndex)
  }

  const loadWorkOrderData = async () => {
    const woNum = getURLSearchParams().get('work_order_number')
    toggleRender(true)
    setError(false)
    setNumWorkOrders(0)
    setWorkOrder({})
    try {
      const response = await fetchData(`work_order/${woNum}`)
      let numWorkOrders = 0
      /* istanbul ignore next */ if (response.work_order_number) {
        response.visits ? setVisits(response.visits) : setVisits([])
        setWorkOrder(response)
        numWorkOrders = 1
      }
      setNumWorkOrders(numWorkOrders)
    } catch {
      setError(true)
    } finally {
      toggleRender(false)
    }
  }

  const subheaderTexts = [
    {
      strong: 'Work Order',
      normal: workOrderNumber,
      icon: (
        <span className={classes.clipboardWrapper}>
          <Clipboard wonum={workOrderNumber} />
        </span>
      ),
    },
    {
      strong: 'Site',
      normal: workOrder?.location_id,
    },
    {
      strong: 'Address',
      normal: numWorkOrders
        ? `${workOrder?.address_line1}, ${workOrder?.city}, ${workOrder?.state}`
        : null,
    },
    {
      strong: 'Phone',
      normal: workOrder?.phone_number,
    },
  ]

  const commonTextFieldProps = {
    fullWidth: true,
    disabled: true,
    InputLabelProps: { shrink: true },
    InputProps: {
      classes: {
        disabled: classes.inputProps,
      },
    },
    variant: 'filled',
  }

  const commonGridItemProps = {
    item: true,
    xs: 6,
  }

  return (
    <>
      <Header />
      <Container maxWidth="xl" disableGutters>
        <AlertGroup
          loading={shouldRender}
          error={error}
          numWorkOrders={numWorkOrders}
          emptyText="No details found for this work order."
          successText="Your work order details have loaded."
          errorText="There was a problem loading work order details."
        />
        <Alert severity="warning" className={classes.statusInfo}>
          <strong>NOTE:&nbsp;</strong>To add Line Items, the work order status
          must <u>NOT</u> be set to any of the following:&nbsp;
          <strong>APPR, PREVIEW, COMP</strong>
        </Alert>
        <Grid container className={classes.gridContainer} spacing={2}>
          <Grid item xs={12} container className={classes.subheaderWrapper}>
            <Grid item container alignItems={'center'} xs={12} sm={9}>
              <Button
                className={classes.button}
                disabled={index === 0 || shouldRender}
                onClick={handlePrevious}
              >
                <KeyboardArrowLeft />
              </Button>
              <Typography>
                {displayCount} of {totalCount}
              </Typography>
              <Button
                className={classes.button}
                onClick={handleNext}
                disabled={currentCount === totalCount || shouldRender}
              >
                <KeyboardArrowRight />
              </Button>
              {subheaderTexts.map((subheaderText, index) => {
                return (
                  <Typography
                    variant="h6"
                    component="span"
                    key={index}
                    className={classes.typography}
                  >
                    <strong>{subheaderText.strong}:&nbsp;</strong>
                    {subheaderText.normal}
                    {subheaderText.icon}
                  </Typography>
                )
              })}
            </Grid>
            <Grid
              item
              container
              justifyContent="flex-end"
              alignItems="center"
              xs={12}
              sm={3}
            >
              <AttachmentUploadButton />
            </Grid>
          </Grid>
          <Grid item xs={3} lg={2}>
            <WorkOrderHistory />
          </Grid>
          <Grid item container xs={6} lg={6} spacing={2}>
            <Grid {...commonGridItemProps}>
              <TextField
                label={'Asset'}
                value={workOrder?.asset_name || ''}
                {...commonTextFieldProps}
              />
            </Grid>

            <Grid {...commonGridItemProps}>
              <TextField
                label={'Not To Exceed (NTE)'}
                value={workOrder?.nte_amt || ''}
                {...commonTextFieldProps}
              />
            </Grid>

            <Grid {...commonGridItemProps}>
              <TextField
                label={'Problem'}
                value={workOrder?.problem_code || ''}
                {...commonTextFieldProps}
              />
            </Grid>

            <Grid {...commonGridItemProps}>
              <ChangeStatus />
            </Grid>

            <Grid {...commonGridItemProps}>
              <TextField
                label="Area of Store"
                value={workOrder?.pm_space_asset_location || ''}
                {...commonTextFieldProps}
              />
            </Grid>

            <Grid {...commonGridItemProps}>
              <TextField
                label={'Decline Reason'}
                value={workOrder?.vendor_decline_reason || ''}
                {...commonTextFieldProps}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Short Description"
                value={workOrder.work_order_description || ''}
                {...commonTextFieldProps}
              />
            </Grid>
          </Grid>
          <Grid item xs={3} lg={4}>
            <ImageCarousel inline={true} />
            <ImageDialog />
          </Grid>
          <Grid className={classes.gridContainerFullWidth} item xs={12}>
            <Accordion
              onChange={(e) => {
                trackCustomEvent(
                  'Viewed Long Description',
                  workOrderNumber,
                  userInfo.firstName + userInfo.lastName,
                  userInfo.technicianId,
                )
                return e
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Long Description</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {
                    // let's strip out any HTML often included in long description...
                    // typography component won't render it as HTML anyway -cs
                    workOrder?.detail_description?.replace(/<[^>]*>?/gm, '')
                  }
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
        <SubNavTabs
          woNumsArrayParams={workOrderArray}
          currentCount={currentCount}
          totalCount={totalCount}
          companyId={companyId}
        />
      </Container>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    toggle: state.workOrdersReducer.toggle,
    workOrder: state.workOrdersReducer.workOrder,
    companyId: state.authCredentialsReducer.companyId,
    workOrders: state.workOrdersReducer.workOrders,
    shouldRender: state.linearProgressReducer.shouldRender,
    userInfo: state.authCredentialsReducer.auth.session.userInfo,
  }
}

const mapDispatchToProps = {
  toggleRender,
  setMessage,
  setVisits,
  setWorkOrder,
  setWorkOrders,
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkOrderDetails)
