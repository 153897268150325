/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { setURLSearchParams } from '../../../windowManager'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import { toggleRender } from '../../MatLinearProgress/actionCreator'
import { putData } from '../../../service/common/HttpService'
import { connect } from 'react-redux'
import { getURLSearchParams } from '../../../windowManager'
import { setMessage } from '../../UserFeedback/actionCreator'
import {
  CANCEL,
  DEFAULT_ERROR_MESSAGE,
  GOLDEN_RATIO_AS_PERCENT,
  WORK_ORDER,
} from '../../../globalConstants'
import { setToggle } from '../WorkOrdersStore/actionCreator'
import { Modal, Paper, Select, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Alert, Stack, createStyles } from '@mui/material'
import Button from '@material-ui/core/Button'
import { SET_MESSAGE_DELAY_FIX } from '../../../globalConstants'

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: `${GOLDEN_RATIO_AS_PERCENT * 100}%`,
    },
    modalHeader: {
      fontWeight: 'bold',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.action,
      padding: theme.spacing(1, 2),
    },
    modalMessage: {
      margin: theme.spacing(1, 0),
    },
    contentStack: {
      margin: theme.spacing(2, 2, 0, 2),
    },
    buttonStack: {
      margin: theme.spacing(2, 0),
    },
  }),
)

const vAccept = { value: 'VACCEPT', label: 'Vendor Accept' }
const vRepair = { value: 'VREPAIR', label: 'Vendor Repaired' }
const vInprg = { value: 'VINPRG', label: 'Vendor In Progress' }
const vDecwo = { value: 'VDECWO', label: 'Vendor Decline Without Charges' }
const vDecwc = { value: 'VDECWC', label: 'Vendor Decline With Charges' }
const vComp = { value: 'VCOMP', label: 'Vendor Complete' }

const vendorActionStatuses = [
  'APPR',
  'VACCEPT',
  'VREPAIR',
  'VINPRG',
  'VDECWC',
  'VDECWO',
  'VCOMP',
  'PAPPR',
  'PDEC',
  'WVFAIL',
]

const statusOptions = {
  APPR: [vAccept, vDecwo, vDecwc],
  PAPPR: [vInprg, vRepair, vComp],
  VACCEPT: [vRepair, vInprg, vDecwo, vDecwc, vComp],
  VREPAIR: [vRepair, vInprg, vComp],
  VINPRG: [vInprg, vRepair, vComp],
  PDEC: [vInprg, vRepair, vDecwo, vDecwc, vComp],
  WVFAIL: [vAccept, vRepair, vInprg, vDecwo, vDecwc, vComp],
  VCOMP: [],
  VDECWO: [],
  VDECWC: [],
}

const declineReasonList = [
  {
    value: 'DO',
    description: 'Decline Other',
  },
  {
    value: 'DSL',
    description: 'Doesn’t service location',
  },
  {
    value: 'DSR',
    description: 'Declined at Store Request',
  },
  {
    value: 'DST',
    description: 'Doesn’t service trade',
  },
  {
    value: 'DUP',
    description: 'Duplicate WO',
  },
  {
    value: 'PTC',
    description: 'Pull Through work not needed',
  },
  {
    value: 'UPW',
    description: 'Unavailable to perform work',
  },
]

export const ChangeStatus = ({
  toggleRender,
  workOrder,
  setMessage,
  shouldRender,
  toggle,
  setToggle,
}) => {
  const workOrderStatus = workOrder.status
  const workOrderNumber = getURLSearchParams().get('work_order_number')
  const [lastStatus, setLastStatus] = useState(workOrderStatus)
  const [declineReason, setDeclineReason] = useState('')
  const [selectedStatus, setSelectedStatus] = useState('')
  const [statusArray, setStatusArray] = useState([])
  const [error, setError] = useState(false)
  const [open, setOpen] = useState(false)
  const [awaitingTargetAction, setAwaitingTargetAction] = useState(false)
  const classes = useStyles()
  const awaitTargetActionMessage = `${workOrderStatus} is a Target Actionable Status. Please await Target response to continue.`

  useEffect(() => {
    workOrderStatus !== lastStatus && workOrderStatus && setMessage()
    setStatusOptions(workOrderStatus)
    disableStatusDropdown()
  }, [workOrderStatus])

  useEffect(() => {
    window.addEventListener('hashchange', onHashChange)
    onHashChange()
    return () => window.removeEventListener('hashchange', onHashChange)
  }, [])

  const disableStatusDropdown = () => {
    setAwaitingTargetAction(!vendorActionStatuses.includes(workOrderStatus))
  }

  const setStatusOptions = (workOrderStatus) => {
    setStatusArray(statusOptions[workOrderStatus] || [])
  }

  const onHashChange = () => {
    const searchParams = getURLSearchParams()
    const addVendorDeclineReason = Boolean(
      searchParams.get('add_vendor_decline_reason'),
    )
    setOpen(addVendorDeclineReason)
  }

  const openModalOrUpdate = (event) => {
    const eventTargetValue = event.target.value
    setSelectedStatus(eventTargetValue)
    if (eventTargetValue === 'VDECWC' || eventTargetValue === 'VDECWO') {
      setError(false)
      setURLSearchParams([{ name: 'add_vendor_decline_reason', value: true }])
    } else {
      updateStatus(eventTargetValue)
    }
  }

  const updateStatus = async (eventTargetValue) => {
    try {
      toggleRender(true)
      setError(false)
      setMessage(
        `Please stay on this page while we update the ${WORK_ORDER} status...`,
      )
      const body = { status: eventTargetValue }
      if (eventTargetValue === 'VDECWC' || eventTargetValue === 'VDECWO') {
        body.vendor_decline_reason = declineReason
      }
      await putData(`work_order/${workOrderNumber}`, body)
      setLastStatus(workOrderStatus)
      setMessage(`${WORK_ORDER} status updated, refreshing ${WORK_ORDER}...`)
      getURLSearchParams().get('add_vendor_decline_reason') && handleClose()
      setToggle(!toggle)
    } catch (error) {
      setError(true)
      const errorMessage = error.response.data
      setMessage(errorMessage.message || errorMessage)
      handleClose()
    } finally {
      toggleRender(false)
    }
  }

  const maximoStatus = () => {
    const checkStatus = (status) => status.value === workOrderStatus
    return (
      workOrderStatus &&
      !statusArray.some(checkStatus) && (
        <MenuItem key={'default'} disabled value={workOrderStatus}>
          {workOrderStatus}
        </MenuItem>
      )
    )
  }

  const handleClose = () => {
    setSelectedStatus('')
    setDeclineReason('')
    setError(false)
    window.history.back()
  }

  return (
    <>
      <TextField
        select
        label={`${WORK_ORDER} Status`}
        value={workOrderStatus || ''}
        onChange={openModalOrUpdate}
        disabled={shouldRender || awaitingTargetAction}
        variant="filled"
        fullWidth
        data-cy="statusSelector"
        onMouseDown={() =>
          awaitingTargetAction &&
          setTimeout(
            () => setMessage(awaitTargetActionMessage, 'info'),
            SET_MESSAGE_DELAY_FIX,
          )
        }
      >
        {maximoStatus()}
        {statusArray.map((option, index) => (
          <MenuItem id="menu-item" key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <Modal open={open} onClose={handleClose}>
        <Paper className={classes.paper}>
          <Typography
            data-cy="declineReasonHeader"
            className={classes.modalHeader}
            variant="h6"
          >
            Select Decline Reason
          </Typography>
          <Stack className={classes.contentStack}>
            {error && <Alert severity="error">{DEFAULT_ERROR_MESSAGE}</Alert>}
            <Typography variant="h5" className={classes.modalMessage}>
              Selected Status: {selectedStatus}
            </Typography>
            <Typography className={classes.modalMessage}>
              If a work order status of declined is selected a reason is
              required.
            </Typography>
            <Select
              label={'Decline Reason'}
              value={declineReason || ''}
              onChange={(e) => setDeclineReason(e.target.value)}
              disabled={shouldRender}
              variant="filled"
              className={classes.modalMessage}
              data-cy="statusSelector"
            >
              {declineReasonList.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {option.value} - {option.description}
                </MenuItem>
              ))}
            </Select>
            <Stack
              justifyContent="flex-end"
              direction="row"
              spacing={1}
              className={classes.buttonStack}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClose}
                aria-label="Cancel"
              >
                {CANCEL}
              </Button>
              <Button
                aria-label="Submit"
                variant="contained"
                color="primary"
                onClick={() => updateStatus(selectedStatus)}
                disabled={shouldRender || !declineReason}
              >
                Submit Status and Reason
              </Button>
            </Stack>
          </Stack>
        </Paper>
      </Modal>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    workOrder: state.workOrdersReducer.workOrder,
    shouldRender: state.linearProgressReducer.shouldRender,
    toggle: state.workOrdersReducer.toggle,
  }
}

const mapDispatchToProps = {
  toggleRender,
  setMessage,
  setToggle,
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeStatus)
